import React from "react"
import * as styles from "../styles/page/second.module.scss"

// 共通コンポーネント
import Meta from "../components/layouts/meta"
import Layout from "../components/layouts/default"
import BreadCrumb from "../components/layouts/bread-crumb"

// markup
const NotFoundPage = () => {
  const title = '404 Not Found'
  const description = 'お探しのページは見つかりませんでした'
  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path="/404/"
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={`${styles.pageTitleHeading} mb-6`}>{title}</h1>
      </div>

      <main className={styles.pageCard}>
        <p>お探しのページは見つかりませんでした</p>
      </main>

    </Layout>
  )
}
export default NotFoundPage
